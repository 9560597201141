* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    --div-bd-radius: 15px;
}

.App {
    position: relative;
    /*left: 270px;*/
    top: max(9vh, 70px);
    background-color: white;
    /*max-width: calc(100% - 270px);*/
    /*padding-left: 4.5vw;
    padding-right: 4.5vw;*/
}

.App > *{
    padding-left: 2vw;
    padding-right: 3vw;
}

.nav, .page {
    padding-bottom: 15px;
    padding-top: 10px; 
}

.nav {
    position: fixed;
    top: max(8vh, 60px);
    left: 0;
    border: 0px;
    border-right: 1px solid hsla(0, 0%, 0%, 0.226);
    width: 270px;
    height: min(92vh, calc(100vh - 60px));
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: hsla(0, 0%, 0%, 0) hsla(0, 0%, 100%, 0);
    z-index: 6;
    background-color: white;
    overscroll-behavior: contain;
    display: none;
}

.nav:hover {
    scrollbar-color: hsla(0, 0%, 0%, 0.226) hsla(0, 0%, 100%, 0);
}