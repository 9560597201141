.comments {
    margin-top: 20px;
}

.comment {
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 12px 0px;
    margin-left: 10px;
    background-color: hsla(0, 0%, 50%, 0.09);
    border-radius: 20px;
    position: relative;
    padding: 2px 5px 5px 0px;
}

.comment .comment-details {
    min-height: 30px;
    /*margin: auto 0px;*/
    align-content: center;
    margin-bottom: 5px;
    
}

.comment .icon {
    width: 20px;
    position: absolute;
    left: -10px;
    top: 2px;
    background-color: hsla(0, 100%, 0%, 0.2);
    height: 20px;
    border-radius: 50%;
}

.comment .comment-time {
    font-size: 12px;
    margin-left: 15px;
    font-weight: 300;
}

.comment > * {
    margin-left: 20px;
}

.comment:hover {
    box-shadow: 3px 3px 2px 3px hsla(0, 100%, 0%, 0.2)
}

.comment-body li {
    white-space: normal;
    list-style-position: inside;
    * {
        display: inline;
    }
}