header {
    position: fixed;
    top: 0px;
    height: 8vh;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.226);
    column-gap: 2vw;
    background-color: white;
    z-index: 10;
    width: 100%;
    left: 0;
}

.navmenu-navlink {
    height: 70%;
    display: flex;
    column-gap: 20px;
    align-items: center;
    color: hsla(0, 0%, 0%, 0.6);
    
}

.navmenu-navlink .nav-menu {
    transform: scale(1.5);
    display: none;
}

.nav-link {
    text-decoration: none;
    color: inherit;
}

.logo-caption {
    display: flex;
    /*max-height: 10%;*/
    column-gap: 5px;
    align-items: center;
    /*overflow: hidden;*/
}

.logo-caption img {
    width: 50px;
}

.search-form {
    flex: 10 1 0;
    /*border: 1px solid red;*/
    display: flex;
    position: relative;
    height: 70%;
    border-radius: 20px;
    background-color: hsla(225, 22%, 64%, 0.241);
    max-width: 600px;
}

.login-box {
    visibility: hidden;
}

.login-box * {
    margin-left: 10px;
}

.logo-caption button {
    line-height: 0.5;
    letter-spacing: -1px;
    font-weight: bold;
    border: none;
    background-color: hsla(0, 0%, 0%, 0);
}

.logo-caption .caption {
    font-size: 26px;
    font-weight: bold;
}

.caption .span {
    color: red;
}

.search-form .icon {
    position: absolute;
    left: 17px;
    align-self: center;
    transform: scale(1.4);
    opacity: 0.5;
}

.search-form .searchInput {
    border: none;
    flex: 1 1 0;
    box-sizing: border-box;
    padding-left: 40px;
    background-color: hsla(0, 0%, 0%, 0);
    outline: none;
}


@media only screen and (max-width: 1200px) {
    .navmenu-navlink .nav-menu {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .logo-caption .caption {
        display: none;
    }
}