.main {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.post.skeleton > * {
    padding: 0px;
}

.post {
    border-radius: var(--div-bd-radius);
    padding: 5px 40px;
    /*border-bottom: 1px solid hsla(0, 100%, 0%, 0.2);*/
    box-shadow: 0px 3px 2px 1px hsla(0, 100%, 0%, 0.1);
}

.post > * {
    margin: 10px 0px;
    padding: 0px 10px;
}

.post-details {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.post-details .icon {
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.post-details .post-time {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 300;
}

.post .post-text, .post .post-linktext {
    white-space: pre-wrap;
    word-break: break-word;
}

.post-text {
    border-radius: 10px;
    max-height: 5em;
}

.post-text li {
    white-space: normal;
    list-style-position: inside;
    * {
        display: inline;
    }
}

.post-text.overflow {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: clip;
    max-height: max-content;
    padding-top: 3px;
    padding-bottom: 5px;
}

.post-text:hover.overflow {
    background-color: hsla(0, 0%, 50%, 0.07);
    cursor: pointer;
}

.post-text.show {
    max-height: max-content;
    box-shadow: none;
    background-color: inherit;
    display: block;
}

.post-img, .post-video {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    background-image: var(--image-url), linear-gradient(hsla(17, 5%, 45%, 1), hsla(17, 5%, 45%, 1));
    background-blend-mode: hue;
    backdrop-filter: blur(10px);
    border-radius: var(--div-bd-radius);
    margin-bottom: 0px;
}

.interactions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    min-height: 25px;
}

.ups {
    margin: auto 10px;
}

.comments-button:hover {
    cursor: pointer;
}

.upvote, .downvote, .comments-button {
    transform: scale(1.5);
}

.post .num-comments {
    margin: auto 7px;
    margin-right: 0px;
}

@media only screen and (max-width: 1100px) {
    .post {
        padding: 5px 15px;
    }
}

@media only screen and (max-width: 750px) {
    .post {
        padding: 5px 10px;
    }

    .post-img, .post-video {
        max-height: 250px;
    }
}