aside {
    background-color: hsl(0, 0%, 96%);
    position: sticky;
    top: max(8vh, 60px);
    height: 80vh;
    overflow-y: scroll;
    overscroll-behavior: contain;
    /*scrollbar-width: thin;*/
    scrollbar-color: hsla(0, 0%, 0%, 0) hsla(0, 0%, 100%, 0);
    border-radius: var(--div-bd-radius);
    padding: 5px 9%;
    /*min-width: 270px;*/
    color: hsl(0, 0%, 20%);
}

aside .heading {
    text-transform: uppercase;
}

.sr {
    padding: 10px 10px;
    /*background-color: hsl(0, 0%, 97%);*/
    list-style: none;
}

.sr-figure {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    align-items: center;
    font-size: 14px;
}

.sr-figure:hover * {
    transform: scale(1.2);
}

.sr-figure:hover {
    column-gap: 25px;
}

.sr * {
    color: black;
    text-decoration: none;
}

.sr-figure > .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/*.sr-figure*/ .sr-detail2 {
    font-weight: 300;
}

@media only screen and (max-width: 950px) {
    .main-container aside {
        display: none;
    }  
}

@media only screen and (max-width: 1100px) {
    aside {
        padding: 5px 2% 5px 5%;
    }

    .sr {
        padding-bottom: 0px;
    }
    
    .sr-figure > .icon {
        width: 30px;
        height: 30px;
    }

    .sr-figure .sr-detail2 {
        font-size: 12px;
    }
}