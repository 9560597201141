.page {
    /*max-width: 1100px;*/
    margin: 0 auto;
    padding-left: 4%;
    padding-right: 4%;
}

.main-container {
    display: grid;
    column-gap: 4%;
    grid-template-columns: 72% 24%;
}

.main-container > * {
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .main-container {
        column-gap: 3%;
        grid-template-columns: 69% 28%
    }

    .page {
        padding-left: 3%;
        padding-right: 2%;
    }
}

@media only screen and (max-width: 950px) {
    .main-container {
        grid-template-columns: 100%;
    }
}